import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { throwError } from 'rxjs';
import { UserPw } from '../portale/_models/user-pw';
import { PortalService } from '../portale/_services/portal.service';
import { ConfigService } from '../portale/_services/config.service';
import { ConfirmDialog } from '../confirm_dialog/confirm_dialog.page';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  open = false;

  constructor(
    public configService: ConfigService, public dialog: MatDialog,
    private service: PortalService) {
  }


  /**
   * intercept all XHR request
   * @param request
   * @param next
   * @returns {Observable<A>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error, caught) => {
      // intercept the respons error and displace it to the console
      this.handleAuthError(error);
      //   return of(error);
      return throwError(error);
    }) as any);
  }


  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private async handleAuthError(err: HttpErrorResponse): Promise<Observable<any>> {
    if (err.status === 401 || err.status === 403) {
      this.alert('Sessione scaduta');
      let model: UserPw = new UserPw();
      model.username = this.configService.sanybeeUser;
      model.password = this.configService.sanybeePwd;
      let baseurl = window.location.hostname;
      this.service.login(model, "", baseurl).then(res => {
      });
    }
    else {
      if (!this.open) //&& !this.configService.modalOpen)
        await this.alert(err.error.message ? err.error.message : 'Errore inaspettato da parte del servizio');
      return throwError(err);
    }
  }



  alert(msg: string) {
    let dialog = this.dialog.open(ConfirmDialog, {
      data: {
        titolo: 'Attenzione!',
        testo: msg,
        btnOk: true
      }
    });
    dialog.afterClosed().subscribe((result: any) => {
      this.open = false;
    })
  }


}
