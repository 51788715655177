<div style="text-align: center;">
    <app-testata></app-testata>
    <div style="margin-top:2%">
        <div style="text-align: center !important">
            <input name="username" type="text" class="form-control" style="width: 30%;margin-left:35%" placeholder="Username*" [(ngModel)]="model.username" required ngModel>
        </div>
        <div style="text-align: center;margin-top:1%">
            <input name="password" class="form-control" type="password" style="width: 30%;margin-left:35%" placeholder="Password*" [(ngModel)]="model.password" required ngModel>
        </div>
    </div>
    <div padding style="margin-top:2%">
        <button (click)="accesso()" class="btn btn-primary" style="width: 30%;"  [disabled]="!model.username || !model.password" type="submit">Accedi ➜</button>
    </div>
</div>