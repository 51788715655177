import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoginRoutingModule } from './login-routing.module';
import { LoginPage } from './login.page';
import { PortalService } from '../portale/_services/portal.service';
import { Http, ConnectionBackend, RequestOptions, HttpModule } from '@angular/http';
import { ConfigService } from '../portale/_services/config.service';
import { TestataModule } from '../testata/testata.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    LoginRoutingModule,
    TestataModule
  ],
  declarations: [LoginPage],
  providers:[PortalService,ConfigService]
})
export class LoginModule {}
