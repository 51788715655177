import { Pagina } from './../_models/pagina';
import { Alias } from './../_models/alias';
import { Portale } from './../_models/portale';
import { StringObject } from './../../_models/string_object';
import { Label } from './../../_models/label';
import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { map, finalize } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { UserInfo } from '../_models/userinfo';
import { UserPw } from '../_models/user-pw';
import { Ruolo } from '../_models/ruolo';
import { Funzioni } from '../_models/funzioni';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';



@Injectable()
export class PortalService {
    private jsonn: any;
    authorizated = false;
    public token: string;
    loading: any;
    public work=false;

    constructor(
        private http: Http, 
        private spinnerService: NgxSpinnerService,
        private httpClient: HttpClient, private configService: ConfigService) {
    }

    setToken(token: string) {
        this.configService.alertEvent = false;
        this.jsonn = JSON.parse(localStorage.getItem('currentUser'));
        this.jsonn.token = token;
        localStorage.setItem('currentUser', JSON.stringify(this.jsonn));
    }

    sessionescaduta() {
        localStorage.removeItem('currentUser');
        if (!this.configService.alertEvent) {
            this.configService.alertEvent = true;
            window.location.href = this.configService.urlbaseLogin;
        }

    }

    createAuthorizationHeader(headers: Headers) {
        if (JSON.parse(localStorage.getItem('currentUser')) != null) {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            this.token = currentUser.token;
            headers.append('Access-Control-Allow-Credentials', 'true');
            headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type,Authorization, Accept');
            headers.append('Access-Control-Allow-Methods', 'POST,OPTIONS,GET,PUT,DELETE');
            headers.append('Access-Control-Allow-Origin', '*');
            headers.append('Accept', 'application/json');

            headers.append('Authorization', 'Bearer ' + this.token);
        } else {
            headers.append('Access-Control-Allow-Credentials', 'true');
            headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type,Authorization, Accept');
            headers.append('Access-Control-Allow-Methods', 'POST,OPTIONS,GET,PUT,DELETE');
            headers.append('Access-Control-Allow-Origin', '*');
            headers.append('Accept', 'application/json');
        }

    }

    presentLoading() {
        this.spinnerService.show();
        // if (!this.loading) {
        //     this.loading = await this.loadingCtrl.create({
        //         message: 'Attendere...',
        //         duration: 2000
        //     });
        //     await this.loading.present();
        // }
    }
    dismissLoading() {
        this.spinnerService.hide();
        // if (this.loading) {
        //     this.loading.dismiss();
        //     this.loading = null;
        // }
    }

    getMenuItems(user: UserInfo, ptid: number): Promise<any[]> {

        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();



        return this.http.get(this.configService.portalUrl + 'sidebarmenu/' + user.usr_username + '/' + ptid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as any[];
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());



    }
    sendPwMail(username: string): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + 'sendpwmail/' + username, { headers: tempheaders })
            .toPromise()
            .then(response => {
                return response.json() as number;
                // this.setToken(response.headers.get("token"))
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    updatePortal(username: string, portale: Portale) {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.put(this.configService.portalUrl + '/update/portale/' + username, portale, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    SaveAssRuoli(username: string, addruoli: Ruolo[], usrid: number): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.put(this.configService.portalUrl + '/update/ruoli/' + username + '/' + usrid, addruoli, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());

    }
    getRuoliByUserId(username: string, usrid: number): Promise<Ruolo[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/ruolobyuserid/' + username + '/' + usrid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Ruolo[];
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }

    GetRuoloByValue(username: string, usrvalue: string): Promise<Ruolo> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/GetRuoloByValue/' + username + '/' + usrvalue, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Ruolo;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    updateRuolo(username: string, addruolo: Ruolo, rlid: number): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.put(this.configService.portalUrl + '/up/ruolo/' + username + '/' + rlid, addruolo, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());

    }
    getRuolo(username: string, rlid: number): Promise<Ruolo> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/ruolobyid/' + username + '/' + rlid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Ruolo;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    addRuolo(username: string, addruolo: Ruolo): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); 
        this.presentLoading();
        const portale = JSON.parse(localStorage.getItem('portale'));
        return this.http.post(this.configService.portalUrl + '/add/ruolo/' + username + '/' + portale.pt_id, addruolo
            , { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    updateUser(username: string, adduser: UserInfo, usrid: number): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); 
        this.presentLoading();
        const portale = JSON.parse(localStorage.getItem('portale'));
        return this.http.put(this.configService.portalUrl + 'up/user/' + username + '/' + portale.pt_id + '/' + usrid, adduser,
            { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());

    }
    addUser(username: string, adduser: UserInfo): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        const idPortale = JSON.parse(localStorage.getItem('portale')).pt_id;
        return this.http.post(this.configService.portalUrl + 'add/user/' + username + '/' + idPortale, adduser, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    getUser(username: string, usrid: number): Promise<UserInfo> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        const portale = JSON.parse(localStorage.getItem('portale'));
        return this.http.get(this.configService.portalUrl + 'userbyid/' + username + '/' + usrid + '/' + portale.pt_id,
            { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as UserInfo;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    deleteUser(username: string, usrid: string): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.delete(this.configService.portalUrl + 'del/user/' + username + '/' + 0 + '/' + usrid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();

                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;

                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());


    }
    updateUserPW(username: string, adduser: UserInfo, usrid: number): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.put(this.configService.portalUrl + '/up/userpw/' + username + '/' + usrid, adduser, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());

    }
    recuperaFile(username: string, doctablename: string, docidkey: number, tipo: number): Promise<StringObject> {
        const tempheaders = new Headers();
        const host = window.location.hostname;
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + 'getdoc/' + username + '/' + doctablename + '/' + docidkey + '?tipo=' + tipo + '&host=' + host, { headers: tempheaders })
            .toPromise().then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();

                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as StringObject;

                }
            }).catch(err => this.handleError(err));
    }
    getLabels(user: string, key: string): Promise<Label[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/getlabels/' + user + '/' + key, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Label[];
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    getModule(username: string, modNome: string): Promise<any> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/modulebyname/' + username + '/' + modNome, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as any;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    getFunzioniByUser(username: string, modNome: string): Promise<Funzioni[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/funzionibyuser/' + username + '/' + modNome, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Funzioni[];
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    AddFunzione(username: string, mdfunome: string, modNome: string, moddescrizione: string): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.post(this.configService.portalUrl + '/add/funzione/' + username + '/' + mdfunome + '/' + modNome, moddescrizione,
            { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    deletefunzione(username: string, mdfuid: number): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();

        return this.http.delete(this.configService.portalUrl + 'del/funzione/' + username + '/' + mdfuid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();

                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;

                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());


    }
    SaveAssRoleFunc(username: string, funzioni: Funzioni[]): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.put(this.configService.portalUrl + '/saveassrlfu/' + username, funzioni, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());

    }
    getFunzioni(username: string, modNome: string): Promise<Funzioni[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        const portale = JSON.parse(localStorage.getItem('portale'));
        return this.http.get(this.configService.portalUrl + '/funzionibymodnome/' + username + '/' + modNome + '/' + portale.pt_id,
            { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Funzioni[];
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }

    getViewUser(username: string, usrusername: string, orderby: string, limitda: number, limitper: number): Promise<UserInfo[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();

        return this.http.get(this.configService.portalUrl + '/user/' + username + '?usrusername=' + usrusername + '&orderby=' + orderby + '&limitda='
            + limitda + '&limitper=' + limitper, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as UserInfo[];
                }
            }).catch(err => this.handleError(err));


    }
    addPortale(username: string, addportale: Portale): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.post(this.configService.portalUrl + '/add/portale/' + username, addportale, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    updateAlias(username: string, addalias: Alias): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.put(this.configService.portalUrl + 'up/alias/' + username + '/' + addalias.al_id, addalias,
            { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    addAlias(username: string, addalias: Alias, ptid: number): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.post(this.configService.portalUrl + 'add/alias/' + username + '/' + ptid, addalias, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    getAlias(username: string, alid: number): Promise<Alias> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/aliasbyid/' + username + '/' + alid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Alias;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    getPortaliUser(username: string, userid: number): Promise<Portale[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();

        return this.http.get(this.configService.portalUrl + '/portaliutente/' + username + '/' + userid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Portale[];
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    SaveAssPortali(username: string, userid: number, portale: Portale[]): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.put(this.configService.portalUrl + 'saveassportali/' + username + '/' + userid, portale, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());

    }
    getPortali(): Promise<Portale[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + 'sceltaportali', { headers: tempheaders })
            .toPromise()
            .then(response => {

                return response.json() as Portale[];

            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    getPortale(username: string, ptid: number): Promise<Portale> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/portalebyid/' + username + '/' + ptid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Portale;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    getPaginePortale(username: string, ptid: number): Promise<Pagina[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/pagineportale/' + username + '/' + ptid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Pagina[];
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    getPagineRuoli(username: string, rlid: number): Promise<Pagina[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/pagineruoli/' + username + '/' + rlid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Pagina[];
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }

    getPagine(
        username: string, ptid: number, cercaOption: string, cerca: string, orderby: string,
        limitda: number, limitper: number): Promise<Pagina[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/pagine/' + username + '/' + ptid + '?' + cercaOption + '=' + cerca + '&orderby=' + orderby + '&limitda='
            + limitda + '&limitper=' + limitper, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Pagina[];
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    getPagina(username: string, pgid: number): Promise<Pagina> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.get(this.configService.portalUrl + '/paginabyid/' + username + '/' + pgid, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Pagina;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }

    updatePagina(username: string, addpagina: Pagina): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.put(this.configService.portalUrl + 'up/pagina/' + username + '/'
            + addpagina.pg_id, addpagina, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    addPagina(username: string, addpagina: Pagina): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.post(this.configService.portalUrl + 'add/pagina/' + username, addpagina, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }
    SaveAssPagine(username: string, ptid: number, pagine: Pagina[]): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.put(this.configService.portalUrl + 'saveasspagine/' + username + '/' + ptid, pagine, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());

    }
    SaveAssPagineRuoli(username: string, rlid: number, pagine: Pagina[]): Promise<number> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.put(this.configService.portalUrl + 'saveasspagineruoli/' + username + '/' + rlid, pagine, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as number;
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }

    getViewRuoli(username: string, rldescription: string, orderby: string, limitda: number, limitper: number): Promise<Ruolo[]> {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        const portale = JSON.parse(localStorage.getItem('portale'));
        return this.http.get(this.configService.portalUrl + '/ruoli/' + username + '/' + portale.pt_id + '?pt_id=' + portale.pt_id + '&rl_description=' + rldescription + '&orderby=' + orderby + '&limitda='
            + limitda + '&limitper=' + limitper, { headers: tempheaders })
            .toPromise()
            .then(response => {
                if (response.headers.get('invaliddatatoken') === 'true') {
                    this.sessionescaduta();
                } else {
                    this.setToken(response.headers.get('token'));
                    return response.json() as Ruolo[];
                }
            })
            .catch(err => this.handleError(err)).finally(() => this.dismissLoading());
    }



    addOperatore(username: string, ruolo: string, us: any) {
        this.presentLoading();
        let curr: UserInfo;
        curr = JSON.parse(localStorage.getItem('currentUser'));
        const portale = JSON.parse(localStorage.getItem('portale'));
        return this.httpClient.post(this.configService.portalUrl + 'add/operatore/' + curr.usr_username +
            '/' + portale.pt_id + '/' + ruolo, us,
            { headers: this.configService.createAuthorizationHeader(curr), observe: 'response' })
            .pipe(
                map((res: any) => {
                    return res.body;
                }), finalize(() => {
                    this.dismissLoading();
                  })
            );
        }

    login(model: UserPw, UIID: string, baseurl: string): Promise<UserInfo> {
    //    this.presentLoading();
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); 

        return this.http.post(this.configService.portalUrl + 'login' + '/' + baseurl + '/' + 0 +
            '?UIID=' + UIID, model, { headers: tempheaders })
            .toPromise()
            .then(response => {
                const user = response.json() as UserInfo;
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    // tslint:disable-next-line:no-debugger

                    // localStorage.setItem("login", "true"
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    if (user.portali) {
                        localStorage.setItem('portale', JSON.stringify(user.portali[0]));
                    }

                    localStorage.setItem('login', 'true');

                    this.token = user.token;
                    this.authorizated = true;
                    return response.json() as UserInfo;
                }
            })
           .finally(() => this.dismissLoading());
        // .catch(this.handleError);
        /* return this.http.post(this.rdpnt.urlService+'/login', JSON.stringify({ username: username, password: password }))
             .map((response: Response) => {
                 // login successful if there's a jwt token in the response
                 let user = response.json();
                 if (user && user.token) {
                     // store user details and jwt token in local storage to keep user logged in between page refreshes
                     localStorage.setItem('currentUser', JSON.stringify(user));
                 }
             });*/
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        // localStorage.removeItem('currentUser');
    }
    private handleError(error: Response | any) {

        if (error.headers.get('invaliddatatoken') === 'true') {

            this.sessionescaduta();

        }
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {

            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return Promise.reject(errMsg);
    }

    public IsSuperUser(): boolean {
        let res = false;
        let currentUser: UserInfo;
        currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser != null && currentUser.authorities.includes('ROLE_SUPERADMIN')) {
            res = true;
        }
        return res;
    }

    public HavePermission(funzioni: Funzioni[], namePermission: string): boolean {
        let res = false;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < funzioni.length; i++) {
            if (funzioni[i].mdfu_nome === namePermission) {
                res = true;
                break;
            }
        }
        return res;
    }

    public CleanOrderByLabels(labels: Label[], labelSort: string): void {

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < labels.length; i++) {
            if (labels[i].lb_fieldname === labelSort) {
                continue;
            }
            labels[i].orderby = '';
        }
    }

    public SetOrderTypeLabels(labels: Label[], OrderBy: string): void {
        if (OrderBy !== undefined && OrderBy.length > 0) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < labels.length; i++) {
                if (labels[i].lb_fieldname === OrderBy.split(' ')[0]) {
                    let TypeOrder = 'ASC';
                    if (OrderBy.split(' ')[1] === 'ASC') {
                        TypeOrder = 'DESC';
                    }
                    labels[i].orderby = TypeOrder;
                    break;
                }
            }
        }
    }

    public ViewHideBtn(idElement: string, view: boolean): void {
        const btn = document.getElementById(idElement);
        if (btn !== undefined) {
            if (view) {
                btn.classList.remove('hidden');
            } else {
                btn.classList.add('hidden');
            }
        }
    }

    inviaMail(obj: any) {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); this.presentLoading();
        return this.http.post(this.configService.portalUrl + 'sendnewusermail', obj,
            { headers: tempheaders })
            .pipe(
                map((res: any) => {
                })
            );
    }

    attivaUtente(uuid: string) {
        const tempheaders = new Headers();
        this.createAuthorizationHeader(tempheaders); 
        this.presentLoading();
         let portale:any=null;
         if(localStorage.getItem('portale')){
         portale = localStorage.getItem('portale');        
         JSON.parse(portale);}
        return this.http.get(this.configService.portalUrl + 'attivaUtente/'+location.hostname+'/'+(portale?portale.pt_id:this.configService.portalID)+'/'+uuid, { headers: tempheaders })
        .pipe(
            map((res: any) => {
                return Number(res._body);
              //return this.configService.setToken(res);
            }), finalize(() => {
              this.dismissLoading();
            })
          );
    }
    
    getUserByUsername(username: string){
        let curr: UserInfo;
        curr = JSON.parse(localStorage.getItem('currentUser'));
        return this.httpClient.get(this.configService.portalUrl + 'getUserbyUsername/' + username+'/' ,
            { headers: this.configService.createAuthorizationHeader(curr), observe: 'response' })
            .pipe(
                map((res: any) => {
                    return res.body;
                })
                );
            }


}
