<div style="text-align: center;margin-top:1%;font-weight: bold;margin-bottom:40px">
    <div *ngIf="manager">
        <button *ngIf="username!=''" (click)="logOut()" style="margin-left: 45%;" class="btn btn-primary" type="submit">{{username}}</button>
        <br>
        <h2>SANYBEE</h2>
        <a href="https://portale.sanybee.com">
        <img src="../assets/img/logo.JPG" style="width: 10vw !important;">
    </a>
    </div>



    <div *ngIf="!manager">
        <h2>SANYBEE</h2>
        <a href="https://portale.sanybee.com">
        <img src="../assets/img/logo.JPG" style="width: 25vw !important;">
    </a>
    </div>

</div>