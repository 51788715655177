import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
   },
  {
    path: '',
    loadChildren: () => import('./tab1/tab1.module').then(m => m.Tab1PageModule)
  },

  {
    path: 'dettaglio/:loc_id',
    loadChildren: () => import('./dettaglio/dettaglio.module').then(m => m.DettaglioModule)
  },
  {
    path: 'dett-immobili/:imm_id',
    loadChildren: () => import('./dett-immobili/dett-immobili.module').then(m => m.DettImmobiliPageModule)
  },
  {
    path: 'dett-locali/:loc_id',
    loadChildren: () => import('./dett-locali/dett-locali.module').then(m => m.DettLocaliPageModule)
  },
  {
    path: 'mgr-utenti',
    loadChildren: () => import('./mgr-utenti/mgr-utenti.module').then(m => m.MgrUtentiPageModule)
  },
  {
    path: 'manager-immobili',
    loadChildren: () => import('./mgr-immobili/mgr-immobili.module').then(m => m.MgrImmobiliPageModule)
  },
  {
    path: 'abilitazioni/:usr_username',
    loadChildren: () => import('./abilitazioni/abilitazioni.module').then(m => m.AbilitazioniPageModule)
  },
  {
    path: 'abilitazioni-locali/:loc_id/:isImmobile',
    loadChildren: () => import('./abilitazioni-locali/abilitazioni-locali.module').then(m => m.AbilitazioniLocaliPageModule)
  },
  {
     path: 'updpassword/:usr_id',
     loadChildren: () => import('./updpassword/updpassword.module').then(m => m.UpdPasswordPageModule)
  },
  {
    path: 'dett-utente/:usr_username',
    loadChildren: () => import('./dett-utente/dett-utente.module').then(m => m.DettUtentePageModule)
 },
 {
  path: 'abilitazioniGestore/:usr_username',
  loadChildren: () => import('./abilitazioniGestore/abilitazioniGestore.module').then(m => m.AbilitazioniGestorePageModule)
},
{
  path: 'attivazione/:uuid',
  loadChildren: () => import('./attivazione/attivazione.module').then(m => m.AttivazioneModule)
}
];
@NgModule({
  imports: [
     RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}