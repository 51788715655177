<!-- <mat-drawer-container class="example-container" autosize style="height: 10%;">
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <p>Auto-resizing sidenav</p>
        <p>Auto-resizing sidenav</p>
        <p>Auto-resizing sidenav</p>

    </mat-drawer>

    <div class="example-sidenav-content">
        <span class="material-icons" (click)="drawer.toggle()" style="cursor: pointer;">
            menu
            </span>
    </div> 
</mat-drawer-container>-->

<mat-tab-group [(selectedIndex)]="tabIndex" *ngIf="loggato" mat-align-tabs="center" #tabGroup (selectedTabChange)="naviga($event.index)" (click)="tabClick($event)">
    <!-- <mat-tab label="Home"></mat-tab> -->
    <mat-tab label="Immobili"></mat-tab>
    <mat-tab label="Utenti"></mat-tab>
</mat-tab-group>


<ngx-spinner size="medium" type="ball-spin-clockwise"></ngx-spinner>

<simple-notifications>
</simple-notifications>
<router-outlet></router-outlet>