import { Component, OnInit, AfterContentChecked, ChangeDetectorRef} from '@angular/core';
import { Portale } from '../portale/_models/portale';
import { UserInfo } from '../portale/_models/userinfo';
import { ConfigService } from '../portale/_services/config.service';
import { PortalService } from '../portale/_services/portal.service';
import { Router } from '@angular/router';

import { ConfirmDialog } from '../confirm_dialog/confirm_dialog.page';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-testata',
  templateUrl: 'testata.page.html',
  styleUrls: ['testata.page.scss']
})
export class TestataPage implements OnInit,AfterContentChecked {

  constructor(private ref: ChangeDetectorRef,
    public confService: ConfigService,
    private router: Router,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService,    
    private service: PortalService) { }

  error = '';
  user: UserInfo = new UserInfo();
  portale: Portale;
  username = '';
  manager = true;

  ngOnInit() {
    const json = localStorage.getItem('currentUser');
    if (json) {
      this.user = JSON.parse(json);
      if (this.user.usr_username !== this.confService.sanybeeUser) {
        this.username = this.user.usr_username;
      } else {
        this.username = '';
      }
    }
    else {
      this.username = '';
    }
    
    if (location.pathname=='/'){
      this.manager = false;
    }else{
        this.manager = true;
      }    
    this.ref.detectChanges();
  }

  ngAfterContentChecked() {
    this.ngOnInit();
   }
  
   ionViewDidEnter() {
     this.ngOnInit();
   }

  ionViewWillEnter() {
    this.ngOnInit();
  }

  logOut() {
    let dialog = this.dialog.open(ConfirmDialog, {
      data: {
      titolo: 'Logout',
      testo: 'Sei sicuro di voler effettuare il logout?',
    }
  });
  dialog.afterClosed().subscribe(result => {
      if(result){
            this.service.logout();
              this.router.navigateByUrl('/', {replaceUrl: true});
          }        
    });
  }




}
