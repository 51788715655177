import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ImmobiliService } from '../services/immbobiliService';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '../portale/_services/config.service';
import * as notificationConfig from '../../config/notificationConfig.json';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-modal-immobile',
  templateUrl: './modal-immobile.page.html',
  styleUrls: ['./modal-immobile.page.scss'],
})
export class ModalImmobilePage implements OnInit,OnDestroy {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public diagref: MatDialogRef<ModalImmobilePage>,
    private configService: ConfigService,
    private notificationsService: NotificationsService,
    private fb: FormBuilder, private service: ImmobiliService) { this.createForm() }

  angForm: FormGroup;
  obj: any = {};
  upd = false;
  scelta=false;

  ngOnInit() {
    this.configService.modalOpen = true;
    if (this.data) {
      this.obj = this.data.imm;
      this.upd = true;
    }
  }


  ngOnDestroy(){
    this.configService.modalOpen = false;
  }

  createForm() {
    this.angForm = this.fb.group({
      nome: ['', Validators.required],
      indirizzo: ['', Validators.required],
      comune: ['', Validators.required],
      cap: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern("^[0-9]*$")]],
      prov: ['', [Validators.required,Validators.pattern('^[A-Z a-z]{2}$')]],
      note: ['', null],
      auto: [null, null],
    });
  }
  onNoClick(): void {
    this.diagref.close(this.scelta);
  }

  insert() {  
  debugger;
    if (!this.upd) {
      this.service.addImmobile(this.obj).subscribe(res => {
        this.scelta=true;
        this.NotificationSuccess('Inserimento avvenuto con successo',true);
        this.onNoClick();
      }, error => {
        this.NotificationSuccess('Errore nell\' inserimento' + error,false);
      });
    } else {
      this.service.updImmobile(this.obj).subscribe(res => {
        this.scelta=true;
        this.NotificationSuccess('Modifica avvenuta con successo',true);
        this.onNoClick();
      }, error => {
        this.NotificationSuccess('Errore durante la modifica',false);
      });
    }
  }

  NotificationSuccess(msg:string,success:boolean) {      
    if(success){
      this.notificationsService.success(
      msg,
      (<any>notificationConfig).notificationOperationSuccess.content,
      (<any>notificationConfig).notificationOperationSuccess.options,
  )}else{
    this.notificationsService.error(
      msg,
      (<any>notificationConfig).notificationOperationSuccess.content,
      (<any>notificationConfig).notificationOperationSuccess.options,
    )}
    }

}
