import { Portale } from './portale';
export class UserInfo {
    usr_id: number;
    cli_id: number;
    usr_username: string;
    usr_password: string;
    usr_firstname: string;
    usr_lastname: string;
    authorities: string;
    usr_displayname: string;
    usr_mail: string;
    token: string;
    rl_description: string;
    rl_id: number;
    usr_primoaccesso: number;
    foto: string;
    usr_codiceFiscale: string;
    usr_telefono: string;
    usr_language: string;
    usr_dtNascita: Date;
    usr_comuneNascita:string;
    usr_provinciaNascita:string;
    usr_companyname:string;
    usr_sendmail:boolean;
    portali: Portale[];
    totalrows: number;
}