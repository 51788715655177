import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm_dialog',
  templateUrl: './confirm_dialog.page.html',
  styleUrls: ['./confirm_dialog.page.scss'],
})
export class ConfirmDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public diagref: MatDialogRef<ConfirmDialog>,
  ) { }

  testo: string = '';
  titolo: string = '';
  btnOk = false;

  ngOnInit() {
    this.testo = this.data.testo;
    this.titolo = this.data.titolo;
    this.btnOk = this.data.btnOk;
  }

}
