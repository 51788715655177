<h2 mat-dialog-title>{{titolo}}</h2>
<div mat-dialog-content class="mat-typography">
    {{testo}}
</div>
<ng-container *ngIf="!btnOk">
    <div mat-dialog-actions style="float: right;">
        <button mat-button [mat-dialog-close]="false">No</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Si</button>
    </div>
</ng-container>
<div mat-dialog-actions *ngIf="btnOk" style="float: right;">
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Ok</button>
</div>