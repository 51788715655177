<ngx-spinner size="medium" type="ball-spin-clockwise"></ngx-spinner>
<mat-label *ngIf="!upd" style="margin-top: 0;margin-bottom: 2%;">Nuovo Immobile</mat-label>
<mat-label *ngIf="upd" style="margin-top: 0;margin-bottom: 2%;">Modifica Immobile</mat-label>
<mat-divider style="margin-bottom:5%"></mat-divider>
<div class="container" style="overflow-y: auto; max-height: 80vh;">
    <form [formGroup]="angForm">
        <div class="form-group riga">
            Nome
            <input type="text" name="nome" id="nomeForm" formControlName="nome" class="form-control" #nomeForm [(ngModel)]="obj.nome" required>
        </div>
        <div *ngIf="angForm.controls['nome'].invalid && (angForm.controls['nome'].dirty || angForm.controls['nome'].touched)" class="alert alert-danger">
            <div *ngIf="angForm.controls['nome'].errors.required">
                Nome obbligatorio
            </div>
        </div>
        <div class="form-group riga">
            Indirizzo
            <input name="indirizzo" id="indirizzoForm" formControlName="indirizzo" class="form-control" #indirizzoForm [(ngModel)]="obj.indirizzo" required>
        </div>
        <div *ngIf="angForm.controls['indirizzo'].invalid && (angForm.controls['indirizzo'].dirty || angForm.controls['indirizzo'].touched)" class="alert alert-danger">
            <div *ngIf="angForm.controls['indirizzo'].errors.required">
                Indirizzo obbligatorio
            </div>
        </div>
        <div class="form-group riga">
            Comune
            <input name="comune" id="comuneForm" class="form-control" formControlName="comune" #comuneForm [(ngModel)]="obj.comune" required>
        </div>
        <div *ngIf="angForm.controls['comune'].invalid && (angForm.controls['comune'].dirty || angForm.controls['comune'].touched)" class="alert alert-danger">
            <div *ngIf="angForm.controls['comune'].errors.required">
                Comune obbligatorio
            </div>
        </div>
        <div class="form-group riga">
            Cap
            <input name="cap" id="capForm" class="form-control" formControlName="cap" #capForm [(ngModel)]="obj.cap" required>
        </div>
        <div *ngIf="angForm.controls['cap'].invalid && (angForm.controls['cap'].dirty || angForm.controls['cap'].touched)" class="alert alert-danger">
            <div *ngIf="angForm.controls['cap'].errors.required">
                Cap obbligatorio
            </div>
            <div *ngIf="angForm.controls['cap'].errors && !angForm.controls['cap'].errors.required">
                Cap non valido
            </div>
        </div>
        <div class="form-group riga">
            Prov
            <input name="prov" id="provForm" class="form-control" formControlName="prov" #provForm [ngModel]="obj.prov" (ngModelChange)="obj.prov=$event?$event.toUpperCase():''" required>
        </div>
        <div *ngIf="angForm.controls['prov'].invalid && (angForm.controls['prov'].dirty || angForm.controls['prov'].touched)" class="alert alert-danger">
            <div *ngIf="angForm.controls['prov'].errors.required">
                Provincia obbligatoria
            </div>
            <div *ngIf="angForm.controls['prov'].errors && !angForm.controls['prov'].errors.required">
                Provincia non valida
            </div>
        </div>

        <div class="form-group riga">
            Macchina
            <input name="auto" id="auto" style="height: 20px;" class="form-control" type="checkbox" [(ngModel)]="obj.auto" formControlName="auto" #auto>
        </div>
        <div class="form-group riga">
            <p>
                Note
            </p>
            <p><textarea rows="3" cols="40" name="note" [(ngModel)]="obj.note" id="noteForm" class="form-control" formControlName="note" #noteForm></textarea></p>
        </div>
    </form>
    <div class="col-md-12" style="text-align: center;padding-top: 2vh;display: inline-flex; ">
        <button mat-button (click)="onNoClick()" style="margin-right:2vw;width: 20vw;">Annulla</button>
        <button mat-button [disabled]="angForm.status=='INVALID'" (click)="insert()" style="margin-left:2vw;width: 20vw;" cdkFocusInitial>Salva</button>
    </div>
</div>