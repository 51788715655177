import { Injectable} from '@angular/core';
import { Response } from '@angular/http';
import * as config from '../../../config/config.json';
import { throwError } from 'rxjs';
import { Subject, Observable } from 'rxjs';
import { UserInfo } from '../_models/userinfo';
import { HttpHeaders } from '@angular/common/http';


@Injectable()
export class ConfigService {

    public maxpageelement: number = 20;
    public mailmaxpageelement: number = 0;
    public urlCDRService: string;
    public portalUrl: string;
    public urlbaseLogin: string;
    public urlService: string;
    public urlPreview: string;
    public urlRDBService: string;
    public moduloMailService: string;
    public sanybeeUrl: string;
    public urlSitoSanybee:string;
    public urlPoliserviceService: string;
    public urlPoliserviceWorkplanService: string;
    public OraInizioNotturno: number;
    public OraFineNotturno: number;
    public urlPoliserviceStimeComuni: string;
    public clientIdPostPay: string;
    public maxMinutesAttemptTerminalsCall: number;
    public minutesToUpdateTerminals: number;
    public minutesToUpdatePublications: number;
    public alertEvent: boolean = false;
    public dimensioneseparatoredefault: number = 0;
    public cestino: string;
    public inarrivo: string;
    public inviate: string;
    public bozze: string;
    public spam: string;
    public sanybee_pt_id: number;
    public sanybeeUser: string;
    public sanybeePwd: string;
    public modalOpen:boolean;
    public limitperImmobili:number;
    public limitperUtenti:number;
    public limitperAbilitazioni:number;
    public portalID:number;

    private numOfNotSource = new Subject<any>();
    numOfNot$ = this.numOfNotSource.asObservable();
    language = 'it'
    constructor() {
        this.load();
    }
    ngOnInit(): void {

    }
    totNot(not: any) {
        this.numOfNotSource.next(not);
    }

    load() {
        let urlconfig = '';
        let hostname = window.location.hostname;
        let baseurl: any[] = (<any>config).baseurl;
        for (let i = 0; i < baseurl.length; i++) {
            if (baseurl[i].name == hostname) {
                urlconfig = baseurl[i].url;
                this.urlbaseLogin = baseurl[i].urlbaseLogin;
                this.urlPreview = baseurl[i].urlPreview;
                this.clientIdPostPay = baseurl[i].clientIdPostPay;
                break;
            }
        }

        this.portalUrl = (<any>config).portalUrl;
        this.sanybeeUrl = (<any>config).sanybeeUrl;
        this.urlSitoSanybee= (<any>config).urlSitoSanybee;
        this.moduloMailService = urlconfig + (<any>config).moduloMailService;
        this.maxpageelement = (<any>config).maxpageelement;
        this.mailmaxpageelement = (<any>config).mailmaxpageelement;
        this.sanybeeUser = (<any>config).sanybeeUser;
        this.sanybeePwd = (<any>config).sanybeePwd;
        this.limitperAbilitazioni = Number((<any>config).limitperAbilitazioni);
        this.limitperImmobili = Number((<any>config).limitperImmobili);
        this.limitperUtenti = Number((<any>config).limitperUtenti);
        this.portalID = Number((<any>config).portalID);    
    }



    public createAuthorizationHeader(currentUser?: UserInfo): HttpHeaders {
        
        let headers = new HttpHeaders();
        if (currentUser == null) {
            currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
        if (currentUser != null) {
            headers = headers.set('Access-Control-Allow-Credentials', 'true');
            headers = headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type,Authorization, Accept');
            headers = headers.set('Access-Control-Allow-Methods', 'POST,OPTIONS,GET,PUT,DELETE');
            headers = headers.set('Access-Control-Allow-Origin', '*');
            headers = headers.set('rejectUnauthorized', 'false');
            //headers = headers.set('Accept', 'application/json;application/octet-stream;application/pdf;application/x-www-form-urlencoded');
            headers = headers.set('Accept', '*/*');
            headers = headers.set('Authorization', 'Bearer ' + currentUser.token);
        }else{
            console.log("Utente non recuperato");
        }
        return headers;
    }

    public createAuthorizationHeaderForDefault(currentUser: UserInfo,default_user:string): HttpHeaders {
        let headers = new HttpHeaders();
        if (currentUser == null) {
            currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
        if (currentUser != null) {
            headers = headers.set('Access-Control-Allow-Credentials', 'true');
            headers = headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type,Authorization, Accept');
            headers = headers.set('Access-Control-Allow-Methods', 'POST,OPTIONS,GET,PUT,DELETE');
            headers = headers.set('Access-Control-Allow-Origin', '*');
            headers = headers.set('rejectUnauthorized', 'false');
            //headers = headers.set('Accept', 'application/json;application/octet-stream;application/pdf;application/x-www-form-urlencoded');
            headers = headers.set('Accept', '*/*');
            if(currentUser.usr_username!==default_user)
            headers = headers.set('Authorization', 'Bearer ' + currentUser.token);
        }else{
            console.log("Utente non recuperato");
        }
        return headers;
    }



    public extractData(res: Response) {
        // return res.json();
        return res;
    }

    public handleErrorObservable(error: Response | any) {
        // return Observable.throw(error);
        return throwError(error);
    }

    public handleErrorPromise(error: Response | any) {
        return Promise.reject(error.message || error);
   
    }




    parseDate(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('-') > -1)) {
            const str = value.split('-');
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    setToken(res: any){
        if (res) {
    
            let header: HttpHeaders = res.headers;
            let token = header.get('token');
            let currentUser = JSON.parse(localStorage.getItem("currentUser"));
            if(currentUser && token){
            currentUser.token = token;
            localStorage.setItem("currentUser", JSON.stringify(currentUser));
        }
        }
        return res.body;
    }

}
