import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { PortalService } from '../portale/_services/portal.service';
import { Router } from '@angular/router';
import { UserPw } from '../portale/_models/user-pw';
import { Portale } from '../portale/_models/portale';
import { UserInfo } from '../portale/_models/userinfo';
import * as notificationConfig from '../../config/notificationConfig.json';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../confirm_dialog/confirm_dialog.page';
import { NotificationsService } from 'angular2-notifications';


@Component({
  selector: 'app-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss']
})
export class LoginPage implements OnInit {
  user: UserInfo = new UserInfo();
  constructor(    private notificationsService: NotificationsService,
    private service: PortalService, private router: Router,
    public dialog: MatDialog,
    ) {
  }
  error = '';
  model: UserPw = new UserPw();
  portale: Portale;

  ngOnInit() {
    this.model.username = '';
    this.model.password = '';
  }

  accesso() {
    this.model.portale = this.portale;
    const baseurl = window.location.hostname;
    this.model.username = this.model.username.trim();
    this.service.login(this.model, '', baseurl).then(() => {
    let c=JSON.parse(localStorage.getItem("currentUser"));    
      if(c.authorities.toString().includes("OPERATORE_SANYBEE")){
        localStorage.clear();
        let dialog = this.dialog.open(ConfirmDialog, {
          data: {
          titolo: 'Attenzione!',
          testo: 'Possono accedere al portale web solo le utenze gestore!',
          btnOk:true
        }      
      });
      this.router.navigate(['/manager-immobili']);
    }
    else if (c.usr_primoaccesso === 1) {
      this.router.navigate(['/updpassword/' + c.usr_id]);
    }else{
      this.router.navigate(['/manager-immobili']);
    }
      
    }).catch(err => {
      this.NotificationSuccess('Attenzione! Username o Password errati',false);
    });
  }

  NotificationSuccess(msg:string,success:boolean) {      
    if(success){
      this.notificationsService.success(
      msg,
      (<any>notificationConfig).notificationOperationSuccess.content,
      (<any>notificationConfig).notificationOperationSuccess.options,
  )}else{
    this.notificationsService.error(
      msg,
      (<any>notificationConfig).notificationOperationSuccess.content,
      (<any>notificationConfig).notificationOperationSuccess.options,
    )}
    }

}
